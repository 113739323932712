import { customElement, property } from "lit/decorators.js";
import { html, css, LitElement  } from "lit";
import Highcharts from 'highcharts/highcharts';
import STRINGS from '~/lang/strings_el.json';
import Utils from '~/utils.js';
import fontAwesome from '~/modules/fontawesome/css/fontawesome.js';
import style from '~/modules/css.js';
import * as XLSX from "xlsx";

@customElement("policy-cols")
export class PolicyCols extends LitElement {
    static get properties() {
        return {
            classes: { type: Object },
            data: {type: Array, attribute: true, reflect: true },
            tableConf: {type: Array}
        }
    }

    static styles = [fontAwesome,style]
    constructor(){
        super();
        this.classes = { wrapper: true, switchedView: false };
        this.data=[];
        this.tableConf = [
            {property: 'Titlos', header: 'XX'},
            {property: 'Sinolo', header: STRINGS.CHARTS.OPROGRAMMES_WATERFALL.AMOUNT_TOTAL}
        ]
    }
    firstUpdated(changedProperties) {
        this.fetchData();
    }

    fetchData() {
        fetch('https://2027.anaptyxi.gov.gr/GetData.ashx?chart=PolicyTargets')
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.errMsg) {
                    throw new Error(STRINGS.ERRORS.NETWORK_ERROR);
                }else{
                    this.data = data.sort((a,b)=>{return (a.Sinolo>b.Sinolo?-1:1);});
                    this._enableChart();
                    this.initGrid();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
  
    render() {
        return Utils.getChartContainer.call(this,this.data,STRINGS.CHARTS.POLICYTARGETS.COLS_TITLE);
    }  
    
    initGrid(){
        let grid = this.shadowRoot.querySelector("#grid");
        grid.options={
            data: this.data,
            info: false,
            paging: false,
            searching: false,
            columns: [
                {data: 'Titlos', title: STRINGS.CHARTS.POLICYTARGETS.XCOLUMN.toString().replace(" ","<br>")+"<br><br>",className: 'dt-head-center'},
                {data: 'Sinolo', width: Utils.amountColWidth,title: STRINGS.CHARTS.POLICYTARGETS.AMOUNT_TOTAL.toString().replace(" ","<br>")+"<br>€", className: 'dt-head-center dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR');
                }},
                {data: 'Enosiaki', width: Utils.amountColWidth,title: STRINGS.CHARTS.POLICYTARGETS.AMOUNT_EU.toString().replace(" ","<br>")+"<br>€", className: 'dt-head-center dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR');
                }},
                {data: 'Ethniki', width: Utils.amountColWidth,title: STRINGS.CHARTS.POLICYTARGETS.AMOUNT_NATIONAL.toString().replace(" ","<br>")+"<br>€", className: 'dt-head-center dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR');
                }}
            ],
            order: [[1, 'desc']]
        };
    }
    
    _switchView(){
        this.classes.switchedView=!this.classes.switchedView;
        this.shadowRoot.querySelector(".wrapper").classList.toggle("switchedView");
    }

    _download(){
        let filename='Export.xlsx';
        let exportData = this.data.map(a=>{
            let item={};
            item[STRINGS.CHARTS.POLICYTARGETS.XCOLUMN]=a.Titlos;
            item[STRINGS.CHARTS.POLICYTARGETS.AMOUNT_TOTAL]=a.Sinolo;
            item[STRINGS.CHARTS.POLICYTARGETS.AMOUNT_EU]=a.Enosiaki;
            item[STRINGS.CHARTS.POLICYTARGETS.AMOUNT_NATIONAL]=a.Ethniki;
            return item;
        });
        var ws = XLSX.utils.json_to_sheet(exportData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb,filename);
    }

    _enableChart() {
        let series=[
            {
                name: STRINGS.CHARTS.POLICYTARGETS.AMOUNT_TOTAL,
                data: [],
                color: Utils.getCohesionColors("AMOUNT_TOTAL"),
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
            },
            {
                name: STRINGS.CHARTS.POLICYTARGETS.AMOUNT_EU,
                data: [],
                color: Utils.getCohesionColors("AMOUNT_EU"),
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
            },
            {
                name: STRINGS.CHARTS.POLICYTARGETS.AMOUNT_NATIONAL,
                data: [],
                color: Utils.getCohesionColors("AMOUNT_NATIONAL"),
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
            }
        ];
        for (let i=0;i<this.data.length;i++){
            let a = this.data[i];
            if (a.Titlos.indexOf("Interreg")==-1){  // do not show interreg in chart, v5 reqs
                series[0].data.push({name: a.Titlos, y: a.Sinolo/1000000000  });
                series[1].data.push({name: a.Titlos, y: a.Enosiaki/1000000000 });
                series[2].data.push({name: a.Titlos, y: a.Ethniki/1000000000 });
                series[0].data.sort((a,b)=>b.y-a.y);
                series[1].data.sort((a,b)=>b.y-a.y);
                series[2].data.sort((a,b)=>b.y-a.y);
            }
        }
        Highcharts.chart(
            this.shadowRoot.querySelector('#container'), {
                chart: {
                    type: 'column',
                    height: 450,
                },
                title: {
                    text: undefined
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    title: {
                        text: STRINGS.CHARTS.POLICYTARGETS.COLS_YTITLE
                    }
                },
                series: series,
                tooltip: {
                    headerFormat: "<div class='hTooltipHeader'>{point.key}</div><br>",
                    pointFormatter: function() {
                        let x = Utils.round(this.y,2);
                        return "<div class=hTooltipBody'>"+this.series.name+': <b>'+Utils.round(this.y,2).toLocaleString('el-GR')+" "+STRINGS.CHARTS.POLICYTARGETS.COLS_YTITLE+"</b></div>";
                    },
                    useHTML: true
                },
                borderRadius: 5
        });
      }
}